<!--
 * @Author: niweixing
 * @Date: 2021-11-01 14:32:37
 * @LastEditors: niweixing
 * @LastEditTime: 2022-12-29 23:59:28
 * @Description: 
-->
<template>
<v-card elevation="0">
  <v-container fluid class="align-center">
    <v-row >
      <v-col cols="3">
        <v-container fluid>
          <v-row>
            <!-- <v-avatar  class="mr-3 white--text" color="green" size="30">{{ item.item_owner | surename }}</v-avatar> -->
						<UserAvatar  :fullName="item.item_owner | username" :name="item.item_owner | surename" width='30px' height='30px' :userId="item.item_owner" />
            <v-icon color="blue" class="mr-1">{{ icons.mdiAlphaECircleOutline }}</v-icon>
            <span class="pt-1 blue--text">{{ item.item_name }}</span>
          </v-row>
          <!-- labels -->
          <v-row class="pl-8">
            <v-chip x-small class="ma-1 white--text" :key="i" :color="labelInfo(tag).color" small v-for="(tag, i) in item.item_tag.split(',').filter(t => t != '')">{{ labelInfo(tag).name }}</v-chip>
          </v-row>
        </v-container> 
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1">
        <v-container>
          <v-row class="justify-center">
            <span>{{ item.item_progress }}%</span>
          </v-row>
          <v-row>
            <v-progress-linear rounded :value="item.item_progress" height="6"></v-progress-linear>  
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <span class="text-caption ml-2">{{ item.item_start_date | itemFormat }}-{{ item.item_end_date | itemFormat }}</span>
      </v-col>
      <v-col>
        <span class="text-caption ml-3">{{ item.item_task_status }}</span>
      </v-col>
      <v-col cols="3" class="d-flex justify-start">
        <!-- <v-avatar class="mr-1" color="orange" size="30" v-for="(user, i) in members" :key="i">{{ user | surename }}</v-avatar> -->
				<UserAvatar  v-for="(user, i) in members" :fullName="user | username" :name="user | surename" width='30px' height='30px' :userId="user" />
      </v-col>
    </v-row>
  </v-container>
</v-card>
</template>
<script>
import { mdiAlphaECircleOutline } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'ProjectCard',
  props: ['item'],
	components: {
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
  },
  data() {
    return {
      icons: {
        mdiAlphaECircleOutline
      },
      colors: ['red', 'pink', 'orange', 'green', 'blue', 'purple']
    }
  },
  computed: {
    ...mapGetters('label', ['labels']),
    members: function () {
      const temp = this.item.item_participant.split(',');
      return temp.filter(user => user!="");
    }
  },
  methods: {
    labelInfo(id) {
      const index = this.labels.findIndex(label => label.tag_id == id);
      const name = this.labels[index].tag_name;
      const color = this.colors[Number(this.labels[index].tag_color)];
      return {name: name, color: color};
    }
  },
  filters: {
    itemFormat(date) {
      const day = new Date(date);
      return moment(day).format('YYYY/MM/DD')
    }
  },
}
</script>
